.info_header_wrapper {
    display: flex;
    flex-direction: row;
}

.product_title_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--titleFontFamily);
}

.product_title {
    font-size: var(--shadowTitleFontSize);
    margin: 0;
    text-align: center;
    word-break: break-word;
}
.product_title_shadow {
    font-size: var(--shadowTitleFontSize);
    width: 100%;
    text-align: center;
    margin: 0;
    margin-top: var(--productTitleShadowMargin);
    color: rgba($color: #ffffff, $alpha: 0.1);
    word-break: break-all;
}

.product_image_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product_image {
    max-width: 80%;
    max-height: 400px;
}

.product_image_shadow {
    max-width: 50%;
    margin-top: var(--generalMargin);
}

.editor_product_title {
    font-size: var(--shadowTitleFontSize2);
}

.editor_product_title_shadow {
    font-size: var(--shadowTitleFontSize2);
}
