@font-face {
    font-family: "Orbitron-Medium";
    src: url(./fonts/Orbitron-Medium.eot);
    src:
        url(./fonts/Orbitron-Medium.eot?#iefix) format("embedded-opentype"),
        url(./fonts/Orbitron-Medium.otf) format("otf"),
        url(./fonts/Orbitron-Medium.ttf) format("truetype"),
        url(./fonts/Orbitron-Medium.woff) format("woff"),
        url(./fonts/Orbitron-Medium.woff2) format("woff2");
}

@font-face {
    font-family: "MuseoModerno-SemiBold";
    src: url(./fonts/MuseoModerno-SemiBold.eot);
    src:
        url(./fonts/MuseoModerno-SemiBold.eot?#iefix) format("embedded-opentype"),
        url(./fonts/MuseoModerno-SemiBold.otf) format("otf"),
        url(./fonts/MuseoModerno-SemiBold.ttf) format("truetype"),
        url(./fonts/MuseoModerno-SemiBold.woff) format("woff"),
        url(./fonts/MuseoModerno-SemiBold.woff2) format("woff2");
}

:root {
    --textFontFamily: "Orbitron-Medium";
    --titleFontFamily: "MuseoModerno-SemiBold";

    --sectionTitleFontSize: clamp(18px, 2vw, 27px);
    --shadowTitleFontSize: clamp(28px, 2.4vw, 32px);
    --textFontSize: clamp(12px, 1.2vw, 16px);

    --sectionTitleFontSize2: 18px;
    --shadowTitleFontSize2: 24px;
    --textFontSize2: 12px;

    --checkBoxFontSize: clamp(8px, 0.7vw, 12px);
    --checkBoxMargin: clamp(3px, 0.5%, 8px);

    --generalPadding: 5%;
    --generalPadding2: 2% 5% 2% 5%;
    --infoPadding: 5%;
    --infoPaddingButtom: 8%;
    --infoItemMargin: 3%;
    --footerMargin: 10%;
    --formItemMargin: clamp(2px, 3%, 30px);
    --checkBoxMarginTop: clamp(1px, 1.5%, 15px);
    --productTitleShadowMargin: -10px;
    --footerMarginTop: 15%;
    --generalMargin: 5%;
    --qrCodeMargin: 1%;

    --inevvoBlue: #5ab6f3;
    --inevvoGreen: rgb(175, 203, 55);
}

textarea:focus,
input:focus {
    outline: none;
}

@media only screen and (min-width: 1400px) {
    :root {
        --generalPadding2: 1% 5% 2% 5%;
    }
}

@media only screen and (max-width: 992px) {
    :root {
        --sectionTitleFontSize: clamp(19px, 2vw, 24px);
        --shadowTitleFontSize: clamp(28px, 2.25vw, 36px);
        --textFontSize: clamp(14px, 1.1vw, 18px);
    }
}

@media only screen and (max-width: 768px) {
    :root {
        --sectionTitleFontSize: clamp(16px, 2.5vw, 24px);
        --shadowTitleFontSize: clamp(24px, 3.5vw, 36px);
        --textFontSize: clamp(12px, 1.1vw, 18px);
        --formItemMargin: 2%;
        --infoPaddingButtom: 10%;
        --qrCodeMargin: 1.5%;
    }
}

@media only screen and (max-width: 576px) {
    :root {
        --sectionTitleFontSize: clamp(16px, 2vw, 24px);
        --shadowTitleFontSize: clamp(22px, 4vw, 36px);
        --textFontSize: clamp(10px, 1.1vw, 18px);
        --infoPaddingButtom: 12%;
        --qrCodeMargin: 2%;
    }
}

// **********************editor view**********************

@media only screen and (max-width: 1540px) {
    :root {
        --sectionTitleFontSize2: clamp(11px, 1.2vw, 18px);
        --shadowTitleFontSize2: clamp(14px, 1.6vw, 24px);
        --textFontSize2: clamp(8px, 0.8vw, 12px);
    }
}

@media only screen and (max-width: 768px) {
    :root {
        --sectionTitleFontSize2: clamp(16px, 1.5vw, 24px);
        --shadowTitleFontSize2: clamp(24px, 2.25vw, 36px);
        --textFontSize2: clamp(12px, 1.1vw, 18px);
    }
}

@media only screen and (max-width: 576px) {
    :root {
        --sectionTitleFontSize2: clamp(12px, 1.5vw, 24px);
        --shadowTitleFontSize2: clamp(19px, 2.25vw, 36px);
        --textFontSize2: clamp(10px, 1.1vw, 18px);
    }
}

// **********************editor view**********************
