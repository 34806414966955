.editor_footer_wrapper {
    text-align: center;
    padding: 10px 0;
}

.pb_logo {
    width: clamp(60px, 10vw, 100px);
}

.dtu_logo_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    text-decoration: none;
    color: black;
    line-height: 1;
}

.pb_text {
    font-size: clamp(10px, 1.2vw, 20px);
}

.dtu {
    font-size: clamp(15px, 2vw, 28px);
    font-weight: 700;
}
